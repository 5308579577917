import React, { useEffect, useState, useRef } from "react";
import Context from "../../Context";
import { Table, Input } from "reactstrap";
import { Container, Label, Flex } from "../../components";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { thaiDate } from "../../util";
import * as R from "ramda";
import { bankTranslater } from "../../util";

export const ReportRewardwinner = () => {
  const downloadRef = useRef(null);
  const { callApi, configs } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [state, setState] = useState([]);
  const [type, setType] = useState("all");
  const [filteredState, setFilteredState] = useState([]);

  const [csvFileName, setCsvFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState([]); // to stop auto download (only happen on local)

  useEffect(() => {
    if (csvFileName && !downloaded.includes(csvFileName)) {
      downloadRef.current.childNodes[0].click();
      setDownloaded((old) => [...old, csvFileName]);
    }
  }, [csvFileName]);

  const changeRoundDate = (e) => {
    setRoundDate(e.target.value);
  };

  const changeType = (e) => {
    setType(e.target.value);
    if (e.target.value) {
      setFilteredState(state);
    }
    if (e.target.value === "nonScb") {
      setFilteredState(
        state.filter(
          (data) =>
            (data.bankName === "ธนาคารกรุงศรีอยุธยา" ||
              data.bankName === "ธนาคารธนชาต" ||
              data.bankName === "ธนาคารทิสโก้" ||
              data.bankName === "ธนาคารออมสิน" ||
              data.bankName === "ธนาคารอาคารสงเคราะห์" ||
              data.bankName === "ธนาคารอิสลามแห่งประเทศไทย" ||
              data.bankName === "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" ||
              data.bankName === "ธนาคารทหารไทยธนชาต" ||
              data.bankName === "ธนาคารซีไอเอ็มบีไทย" ||
              data.bankName === "ธนาคารยูโอบี" ||
              data.bankName === "ธนาคารแลนด์ แอนด์ เฮ้าส์" ||
              data.bankName === "ธนาคารเกียรตินาคินภัทร") &&
            data.bankName &&
            data.accNo
        )
      );
      return;
    }

    if (e.target.value === "lao") {
      setFilteredState(
        state.filter(
          (data) =>
            data.bankName === "Banque Pour Le Commerce Exterieur Lao Public" ||
            data.bankName === "Lao-Viet Bank Co,Ltd" ||
            data.bankName === "BIC Bank Lao Co., Ltd" ||
            data.bankName === "Lao Development Bank" ||
            data.bankName === "Indochina Bank LTD" ||
            data.bankName === "Maruhan Japan Bank Lao Co.,ltd" ||
            data.bankName === "Agricultural Promotion Bank" ||
            data.bankName ===
              "Military Commercial Joint Stock Bank - Laos Branch" ||
            data.bankName === "KasikornBank in Lao PDR" ||
            data.bankName === "VietinBank Lao limited" ||
            data.bankName === "Acleda Bank Lao Ltd" ||
            data.bankName === "Banque Franco-Lao Ltd" ||
            data.bankName === "Phongsavanh Bank" ||
            data.bankName === "ST Bank LTD" ||
            data.bankName === "Joint Development Bank"
        )
      );
      return;
    }

    if (e.target.value === "isBuyForSomeoneElse") {
      console.log(e.target.value);
      setFilteredState(state.filter((data) => data.isBuyForSomeoneElse));
      return;
    }

    if (e.target.value === "isGuest") {
      setFilteredState(state.filter((data) => data.isGuest));
      return;
    }

    if (e.target.value === "noBank") {
      setFilteredState(
        state.filter(
          (data) =>
            data.bankName === "" ||
            data.bankName === "undefined" ||
            !data.bankName ||
            !data.accNo ||
            typeof data.bankName === "object"
        )
      );
      return;
    }
    setFilteredState(
      state.filter((data) =>
        typeof data.bankName === "object"
          ? data.bankName.bankName === e.target.value
          : data.bankName.includes(e.target.value) &&
            data.accNo &&
            data.bankName
      )
    );
  };

  const handleSearch = async () => {
    let res = await callApi("userPrizes-getAll", {
      limit: 10000,
      startAfter: "",
      queries: [["roundDate", "==", roundDate]],
      roundDate: roundDate,
    });

    setState(res);
    setFilteredState(res);
  };

  const handleDownload = async () => {
    const date = thaiDate();

    const acc = [];

    acc.push(["รายงานผู้ถูกรางวัลทั้งหมด "]);
    type === "noBank" || type === "all"
      ? acc.push([
          "ธนาคาร",
          "เลขบัญชี",
          "ชื่อบัญชี",
          "ชื่อ-นามสกุล",
          "จำนวนเงิน",
          "เลขที่อ้างอิง",
          "ค่าธรรมเนียม",
          "รหัสสาขา",
          "เบอร์โทรศัพท์",
          "อีเมล์",
        ])
      : acc.push([
          "ธนาคาร",
          "เลขบัญชี",
          "ชื่อบัญชี",
          "จำนวนเงิน",
          "เลขที่อ้างอิง",
          "ค่าธรรมเนียม",
          "รหัสสาขา",
          "เบอร์โทรศัพท์",
          "อีเมล์",
        ]);
    filteredState.map((value, index) =>
      type === "noBank" || type === "all"
        ? acc.push([
            value.accNo && bankTranslater(value.bankName),
            value.accNo && `${value.accNo.split("-").join("")}`,
            value.accNo && value.accName,
            value.firstName + " " + value.lastName,
            value.totalPrizes,
            value.userId,
            "Payer (OUR)",
            "0001",
            `${value.phone}`,
            value.email || "paranee.t@vproperty.co.th",
          ])
        : acc.push([
            value.accNo && bankTranslater(value.bankName),
            value.accNo && `${value.accNo.split("-").join("")}`,
            value.accNo && value.accName,
            value.totalPrizes,
            value.userId,
            "Payer (OUR)",
            "0001",
            `${value.phone}`,
            value.email || "paranee.t@vproperty.co.th",
          ])
    );
    // <th>{item.bankName}</th>
    //             <th>{item.accNo}</th>
    //             <th>{item.accName}</th>
    //             <th>{item.totalPrizes.toLocaleString()} บาท</th>
    //             <th>{item.userId}</th>
    //             <th>Payer (OUT)</th>
    //             <th>0001</th>
    //             <th>{item.phone}</th>
    //             <th>{item.email || "paranee.t@vproperty.co.th"}</th>

    setCsvData(acc);
    setCsvFileName(`รายงานผู้ถูกรางวัล-${type}.csv`);
  };

  const reportList = {
    all: [],
    kbank: ["ธนาคารกสิกรไทย"],
    scb: ["ธนาคารไทยพาณิชย์"],
    ktb: ["ธนาคารกรุงไทย"],
    bbl: ["ธนาคารกรุงเทพฯ"],
    no_bank: "noBank",
    non_scb: "nonScb",
    lao: "lao",
    isBuyForSomeoneElse: "isBuyForSomeoneElse",
    isGuest: "isGuest",
  };

  let total = 0;
  return (
    <Container>
      <Label.H2 weight={500}>รายงานผู้ถูกรางวัลทั้งหมด</Label.H2>
      <br />
      <Input
        name="select"
        type="select"
        onChange={(e) => changeRoundDate(e)}
        style={{ width: "100px" }}
      >
        {configs.roundDates.map((roundDate) => (
          <option key={roundDate} value={roundDate}>
            {roundDate}
          </option>
        ))}
      </Input>

      <Button onClick={() => handleSearch()}>ค้นหา</Button>

      <Input
        name="select"
        type="select"
        onChange={(e) => changeType(e)}
        style={{ width: "100px" }}
      >
        {Object.entries(reportList).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </Input>
      <br />
      <p>จำนวน {filteredState.length}</p>
      {!R.isEmpty(filteredState) && (
        <Button onClick={() => handleDownload()} style={{ display: "inline" }}>
          Download Excel
        </Button>
      )}

      <div ref={downloadRef}>
        <CSVLink
          data={csvData}
          filename={csvFileName}
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
      {!R.isEmpty(filteredState) && (
        <Table striped bordered>
          <thead>
            <tr>
              <th>ธนาคาร</th>
              <th>เลขบัญชี</th>
              <th>ชื่อบัญชี</th>
              <th>ชื่อ นามสกุล</th>
              <th>จำนวนเงิน</th>
              <th>เลขที่อ้างอิง</th>
              <th>ค่าธรรมเนียม</th>
              <th>รหัสสาขา</th>
              <th>เบอร์โทรศัพท์</th>
              <th>อีเมล์</th>
              <th>ซื้อให้ผู้อื่น</th>
            </tr>
          </thead>
          <tbody>
            {filteredState.map((item, index) => {
              console.log("item.accNo", item.bankName);
              total += item.totalPrizes;
              return (
                <tr key={index}>
                  <th>
                    {typeof item.bankName === "object"
                      ? bankTranslater(item.bankName?.bankName) ||
                        item.bankName?.bankName
                      : bankTranslater(item.bankName) || item.bankName || ""}
                  </th>
                  <th>
                    {typeof item.accNo === "string"
                      ? item.accNo && item.accNo.split("-").join("")
                      : item.accNo.accNo.split("-").join("")}
                  </th>
                  <th>{item.accNo && item.accName}</th>
                  <th>
                    {item.firstName} {item.lastName}
                  </th>
                  <th>{item.totalPrizes}</th>
                  <th>{item.userId}</th>
                  <th>Payer (OUR)</th>
                  <th>0001</th>
                  <th>{item.phone}</th>
                  <th>{item.email || "paranee.t@vproperty.co.th"}</th>
                  <th>{item.isBuyForSomeoneElse ? "Yes" : "No"}</th>
                </tr>
              );
            })}
            <tr>
              <th>รวม</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{total.toLocaleString()}</th>
            </tr>
          </tbody>
        </Table>
      )}
      <br />
      <br />
    </Container>
  );
};

const Button = styled.button`
  margin: 2em 0;
`;
