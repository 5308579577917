import { useState, useEffect, useContext } from "react";

import { collection, doc, onSnapshot } from "firebase/firestore";
import {
  Container,
  Input,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
} from "../../components/base";
import {
  ScrollArea,
  Avatar,
  AvatarImage,
  AvatarFallback,
  Separator,
  Toaster,
  useToast,
  ToastAction,
  Skeleton,
} from "../../components/ui";
import {
  Loading,
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/modules";
import { CgProfile } from "react-icons/cg";
import { BsPiggyBank } from "react-icons/bs";
import { RiShoppingBag3Fill, RiStickyNoteAddFill } from "react-icons/ri";
import { FiUserPlus, FiEdit3 } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { unixToDateAndTime } from "../../util";
import Context from "../../Context";
import { MdPersonSearch } from "react-icons/md";
import { transactionsTranslate, transactionsType } from "../../constants";
import {
  FaCompress,
  FaExpand,
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
  FaShoppingCart,
} from "react-icons/fa";
import { FaCommentMedical } from "react-icons/fa6";
import { MdAccountBalance } from "react-icons/md";
import { banks } from "../../constants";
import { FiShare2 } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";

import { useNavigate, useLocation } from "react-router-dom";

import th from "antd/es/date-picker/locale/th_TH";
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { DatePicker } from "antd";

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: "DD-MM-BBBB",
    fieldDateTimeFormat: "DD-MM-BBBB HH:mm:ss",
    yearFormat: "BBBB",
    cellYearFormat: "BBBB",
  },
};

export const Users = () => {
  const { callApi, isDarkTheme, themes, configs, db } = Context();
  const { toast } = useToast();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [query, setQuery] = useState(
    search.length > 0
      ? { key: "userId", value: search.slice(1) }
      : { key: "phone", value: "" }
  );

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(
    query.key === "" ? localStorage.getItem("selectedUser") : false
  );
  const [selectedUserOrders, setSelectedUserOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getUserOrder();
      getUserInfo();
    } else {
      setUser({});
      setSelectedUser(false);
      localStorage.removeItem("selectedUser");
      setSelectedUserOrders([]);
    }
  }, [selectedUser]);

  const getUserInfo = async () => {
    setUser({ isLoading: true });
    let result = await callApi("users-getUserInfoAdmin", {
      userId: selectedUser,
    });

    result.notes = result?.notes ? result?.notes.reverse() : [];
    setUser(result || false);
  };

  const getUserOrder = async () => {
    setSelectedUserOrders({ isLoading: true });
    const result = await callApi("orders-getAll", {
      roundDate: configs.roundDate,
      queries: [["userId", selectedUser]],
      limit: 10,
      orderBy: ["createdAt", "desc"],
    });

    setSelectedUserOrders(result || []);
  };

  const updateUserInfo = async (userId, key, value) => {
    if (!userId) return;

    const { status } = await callApi("users-edit", {
      id: userId,
      key: key,
      value: value,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
    } else {
      toast({
        variant: "destructive",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
    }
    getUserInfo();
  };

  const fetchUsers = async () => {
    const queries = query.key && query.value ? [[query.key, query.value]] : [];
    setUsers([]);
    const result = await callApi("users-getAll", {
      queries: queries,
      limit: 35,
      orderBy: ["createdAt", "asc"],
    });
    setUsers(result.length > 0 ? result : false);
    if (result.length === 1) {
      setSelectedUser(result[0].id);
    }
  };

  const handleSearch = () => {
    // if (!query.value) {
    //   setQuery((prevState) => ({ ...prevState, key: false }));
    // } else {
    //   if (new RegExp(/^\d+$|^$/).test(query.value)) {
    //     if (query.value.length >= 6) {
    //       setQuery((prevState) => ({ ...prevState, key: "phone" }));
    //     } else {
    //       setQuery((prevState) => ({ ...prevState, key: "userId" }));
    //     }
    //   } else {
    //     setQuery((prevState) => ({ ...prevState, key: "firstName" }));
    //   }
    // }

    fetchUsers();
  };

  const updateNotes = async (e) => {
    const { status, message } = await callApi("users-updateNotes", {
      note: e,
      userId: user.userId,
    });

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัพเดตข้อมูลสำเร็จ`,
      });
      getUserInfo();
      return true;
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัพเดตข้อมูลไม่สำเร็จ`,
      });
      getUserInfo();
      return false;
    }
  };

  return (
    <div className="flex w-full gap-3">
      <Container
        className={` p-1 w-full h-fit shadow-md duration-150 ${
          selectedUser && "w-[300px]"
        }`}
      >
        {/* <Container className="h-[800px] w-full max-w-[300px] rounded-[20px] shadow-xl"> */}
        <div
          className={`flex ${
            selectedUser && "flex-col"
          } items-center gap-2.5 m-2`}
        >
          {/* <div className={`relative z-10 flex-grow`}> */}
          {/* <MdPersonSearch
              className={`absolute w-6 h-6 top-3 right-5 cursor-pointer duration-100 ${
                isDarkTheme ? "text-white" : " hover:text-[rgb(214,212,212)]"
              } ${query.value ? "text-[rgb(214,212,212)]" : "text-input"}`}
              onClick={() => handleSearch()}
            /> */}

          {/* {query?.value && (
              <FaTimes
                className={`absolute w-6 h-6  right-6 cursor-pointer duration-100 ${
                  isDarkTheme
                    ? "text-white"
                    : "text-[rgb(214,212,212)] hover:text-red-200"
                } `}
                onClick={() => setQuery({ key: "", value: "" })}
              />
            )} */}
          <Input
            className="rounded-lg flex-grow"
            placeholder="ค้นหา"
            value={query.value || ""}
            onChange={(e) =>
              setQuery((prevState) => ({
                ...prevState,
                value: e.target.value,
              }))
            }
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          />
          {/* </div> */}
          <Select
            className="cursor-pointer"
            value={query.key}
            onValueChange={(e) => {
              setQuery((prevState) => ({ ...prevState, key: e }));
            }}
          >
            <SelectTrigger
              className={`my-1  ${
                selectedUser ? "w-full" : "w-64"
              } text-md disabled:opacity-100`}
            >
              <SelectValue placeholder={`-`} />
            </SelectTrigger>
            <SelectContent className="bg-black">
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="firstName"
              >
                ชื่อ
              </SelectItem>
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="lastName"
              >
                นามสกุล
              </SelectItem>
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="userId"
              >
                ไอดี
              </SelectItem>
              <SelectItem
                className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                value="phone"
              >
                เบอร์โทรศัพท์
              </SelectItem>
            </SelectContent>
          </Select>
          <button
            className={`h-12 ${
              selectedUser ? "w-full" : "w-52"
            } bg-[#168AFF] flex gap-1 justify-center items-center text-sm text-white hover:opacity-85 duration-200 disabled:hover:bg-gray-950 font-bold rounded-[0.5rem] disabled:cursor-not-allowed disabled:opacity-50`}
            onClick={() => handleSearch()}
          >
            <IoSearch />
            ค้นหา
          </button>
          {/* <div
            className={`flex items-center p-3 font-bold justify-center h-12 rounded-lg duration-100 cursor-pointer ${
              isDarkTheme
                ? "text-[rgb(26,29,32)] bg-white "
                : "bg-[rgb(26,29,32)] text-gray-50"
            } hover:opacity-90`}
          >
            <FiUserPlus />
          </div> */}
        </div>

        {!users ? (
          <div className="flex justify-center items-center h-72">
            ไม่พบสิ่งที่ค้นหา
          </div>
        ) : users.length > 0 ? (
          <ScrollArea
            className={`relative ${
              users.length > 5
                ? `${selectedUser ? "h-[20dvh] xl:h-[80dvh]" : "h-[80dvh]"}`
                : "h-fit"
            } px-2 py-0 my-2 duration-150`}
          >
            {users.map((user, index) => {
              return (
                <UserList
                  userData={user}
                  isDarkTheme={isDarkTheme}
                  key={user.userNo}
                  setSelectedUser={setSelectedUser}
                  isSelectedUser={selectedUser === user.id}
                  isNotLast={index !== users.length - 1}
                />
              );
            })}
          </ScrollArea>
        ) : (
          <div className="flex justify-center items-center h-72">
            <Loading />
          </div>
        )}
        {/* </Container> */}
      </Container>

      {selectedUser && user && (
        <div className="relative w-full flex gap-3 flex-grow">
          <div className="flex flex-col gap-3 items-start flex-grow">
            <UserDetail
              selectedUser={selectedUser || false}
              isDarkTheme={isDarkTheme}
              user={user}
              setUser={setUser}
              setSelectedUser={setSelectedUser}
              updateUserInfo={updateUserInfo}
              getUserInfo={getUserInfo}
            />
            <Transaction
              user={user}
              selectedUser={selectedUser || false}
              getUserInfo={getUserInfo}
            />
            {/* <PointTransaction
              user={user}
              selectedUser={selectedUser || false}
            /> */}
          </div>
          <div className="flex flex-col gap-3 items-start w-2/5">
            <Container className={`relative p-2 h-fit duration-150 w-full`}>
              <div className="w-full">
                <div className="flex ml-2 gap-2 items-center">
                  <RiShoppingBag3Fill className={themes.text} />
                  <Text className="text-md font-bold my-3 ">
                    ออเดอร์งวดปัจจุบัน
                  </Text>
                </div>

                {selectedUserOrders?.isLoading ? (
                  <Container className="w-full flex justify-center items-center h-[400px]">
                    <Loading />
                  </Container>
                ) : selectedUserOrders?.length === 0 ? (
                  <div
                    className={`flex justify-center items-center border border-gray-600 relative h-[360px] p-2 mb-2 rounded-lg`}
                  >
                    <Text className="text-md text-gray-400 my-3 ">
                      ไม่พบออเดอร์
                    </Text>
                  </div>
                ) : (
                  <ScrollArea
                    className={`relative ${
                      selectedUserOrders?.length > 4 ? "h-[360px]" : "h-fit"
                    } p-2 my-0 rounded-lg duration-150`}
                  >
                    {selectedUserOrders.map((order, index) => {
                      return (
                        <div
                          className="mr-1"
                          key={order.shortId}
                          onClick={() =>
                            window.open(`/orders/${order.id}`, "_blank")
                          }
                        >
                          <div
                            className={`flex justify-between w-full min-h-16 items-center px-3 py-2 text-sm font-bold rounded-lg duration-100 cursor-pointer ${
                              isDarkTheme
                                ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
                                : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
                            }`}
                          >
                            <div>
                              <p>{order.shortId}</p>
                              <p className="text-xs opacity-55">
                                {order.size} ใบ |{" "}
                                {order.totalPrice.toLocaleString()}฿
                              </p>
                              <p className="text-[9px] mt-3 opacity-55 truncate">
                                {new Date(order.createdAt).toLocaleDateString(
                                  "th-TH",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                            {order.status === "approved" && (
                              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                                <FaCheckCircle className="text-lg" />
                                <p className="text-[8px] font-light">อนุมัติ</p>
                              </div>
                            )}
                            {order.status === "pending" && (
                              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                                <FaHourglassHalf className="text-lg" />
                                <p className="text-[8px] font-light">
                                  รอดำเนินการ
                                </p>
                              </div>
                            )}
                            {order.status === "canceled" && (
                              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                                <FaTimesCircle className="text-lg" />
                                <p className="text-[8px] font-light">ยกเลิก</p>
                              </div>
                            )}
                            {order.status === "paid" && (
                              <div className="flex flex-col w-14 gap-1 justify-center items-center">
                                <FaMoneyBillAlt className="text-lg" />
                                <p className="text-[8px] font-light">
                                  จ่ายเงินแล้ว
                                </p>
                              </div>
                            )}
                          </div>
                          {index !== selectedUserOrders.length - 1 && (
                            <Separator className="pr-3 my-2" />
                          )}
                        </div>
                      );
                    })}
                  </ScrollArea>
                )}
              </div>
            </Container>
            {/* notes */}
            <Notes notes={user?.notes || []} updateNotes={updateNotes} />
          </div>
        </div>
      )}
    </div>
  );
};

const Transaction = ({ user, selectedUser, getUserInfo }) => {
  const [isLoading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const { themes, callApi, configs } = Context();
  useEffect(() => {
    fetchData();
  }, [user]);

  const fetchData = async () => {
    if (!user.userId) {
      return;
    }
    setLoading(true);

    let result = await callApi("affiliates-getTransactionByUserId", {
      userId: user.userId,
    });

    const sortedData = result.data?.sort((a, b) => b.createdAt - a.createdAt);

    setTransactions(
      sortedData.map((x) => {
        return { ...x, notes: x.notes?.reverse() };
      })
    );

    setLoading(false);
  };

  return (
    <Container className={`relative p-3 h-fit duration-150 w-full`}>
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <BsPiggyBank className={themes.text} />
          <Text className="text-md font-bold">ประวัติรายได้</Text>
        </div>
      </div>
      <div className="my-3">
        <Text className={"text-sm font-medium"}>จำนวนเงินคงเหลือ</Text>
        {/* <Text className="text-md font-bold">จำนวนเงินคงเหลือ</Text> */}
        <div className="flex justify-end items-end gap-2">
          <Text className="text-4xl text-[rgb(59,176,101)] font-bold">
            {(user.buyCash || 0)?.toLocaleString()}
          </Text>
          <Text className="text-md font-bold">บาท</Text>
        </div>
      </div>
      <div className="grid my-3 grid-cols-2 gap-2">
        <DepositDialog
          user={user}
          fetchData={fetchData}
          getUserInfo={getUserInfo}
        />
        <WithdrawDialog
          user={user}
          fetchData={fetchData}
          getUserInfo={getUserInfo}
        />
        {/* <button className="bg-[#c15b78] font-bold text-gray-50 rounded-lg py-2 px-3 hover:opacity-85">
          ถอน
        </button> */}
      </div>
      {transactions ? (
        <div className="">
          <ScrollArea
            className={`${
              transactions.length > 5 ? "h-[800px]" : "h-fit"
            } px-3 border-1 border-gray-200 rounded-lg`}
          >
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <div
                  className={` ${
                    transaction.length - 1 === index ? "border-0" : "border-b-2"
                  } p-4`}
                  key={transaction.shortId}
                >
                  <div
                    className={`flex justify-between items-end w-full bg-white gap-4 duration-150 `}
                  >
                    <div className="flex flex-col">
                      <p
                        className={`text-sm font-bold ${
                          transaction.type === "withdraw"
                            ? "text-[#c15b78]"
                            : "text-[rgb(59,176,101)]"
                        }`}
                      >
                        {transactionsTranslate[transaction.type]}
                      </p>
                      <p className="text-2xl font-bold">
                        {(transaction.amount || 0).toLocaleString()}
                      </p>
                      {transaction.status !== "canceled" && (
                        <p className="text-sm font-medium text-gray-400">
                          คงเหลือ{" "}
                          {(transaction.balanceAfter || 0).toLocaleString()}
                        </p>
                      )}
                      <p className="text-sm font-medium text-gray-400">{`${
                        new Date(transaction.createdAt).toLocaleDateString(
                          "th-TH",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        ) +
                        " | " +
                        new Date(transaction.createdAt).toLocaleString(
                          "th-TH",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        ) +
                        " น."
                      }`}</p>
                      <p className="text-sm font-medium text-gray-400">
                        {transaction.shortId}
                      </p>
                    </div>
                    <div className="self-center">
                      {transaction.status === "approved" ? (
                        <p className="text-md text-green-500 font-medium mr-2">
                          อนุมัติ
                        </p>
                      ) : transaction.status === "pending" ? (
                        <p className="text-md text-[#ffb067] font-medium mr-2">
                          รออนุมัติ
                        </p>
                      ) : (
                        <p className="text-md text-[#c15b78] font-medium mr-2">
                          ยกเลิก
                        </p>
                      )}
                    </div>
                  </div>
                  <TransactionNotes
                    transaction={transaction}
                    fetchData={fetchData}
                  />
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center w-full h-60">
                <p className="text-gray-400 font-semibold">
                  ไม่พบประวัติรายได้
                </p>
              </div>
            )}
          </ScrollArea>
        </div>
      ) : (
        <div className="flex h-72 items-center justify-center p-2">
          <Loading className={`w-6 h-6`} />
        </div>
      )}
    </Container>
  );
};

const TransactionNotes = ({ transaction, fetchData }) => {
  const { toast } = useToast();
  const { callApi, isDarkTheme, themes, configs, profileState } = Context();
  const [noteValue, setNoteValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  const submitNote = async (id) => {
    setLoading(true);
    const { status } = await callApi("affiliates-addNote", {
      orderId: id,
      note: noteValue,
    });
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      setNoteValue("");
      fetchData();
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <p className="mt-3 font-bold">บันทึกเพิ่มเติม</p>
      <div className="flex bg-white rounded-t-lg ">
        <Input
          className=" focus:outline-[rgb(59,176,101)]"
          placeholder="บันทึก"
          value={noteValue}
          onChange={(e) => setNoteValue(e.target.value)}
        />
        {isLoading ? (
          <div className="flex justify-center items-center px-4 m-1 bg-[rgb(59,176,101)] text-xl text-gray-50 rounded-lg duration-200">
            <Loading className="w-3 h-3" />
          </div>
        ) : (
          <button
            className="px-3 my-1 ml-1 mr-0 bg-[rgb(59,176,101)] text-gray-50 rounded-lg hover:opacity-85 duration-200"
            onClick={() =>
              noteValue === ""
                ? toast({
                    className: "bg-[#c15b78]",
                    title: (
                      <div className="flex gap-2 items-center">
                        <FaCheckCircle /> โปรดใส่บันทึกเพิ่มเติม
                      </div>
                    ),
                  })
                : submitNote(transaction.id)
            }
          >
            <RiStickyNoteAddFill className="w-6 h-6" />
          </button>
        )}
      </div>
      <ScrollArea
        className={`${
          transaction.notes?.length === 0
            ? "h-28"
            : transaction.notes?.length >= 2
            ? "h-64"
            : "h-32"
        } w-full rounded-lg bg-white border`}
      >
        {transaction.notes?.length > 0 ? (
          transaction.notes?.map((note) => (
            <div key={note.time} className="relative flex justify-end p-3">
              <div className="flex flex-col">
                <p className="text-xs font-semibold">{note.by} </p>
                <p className="text-lg min-w-48 my-1 px-3 py-2 rounded-lg text-gray-50 font-medium bg-[#168AFF]">
                  {note.note}
                </p>
                <p className="text-xs self-end">
                  {new Date(note.time).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}{" "}
                  น.
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-sm text-center h-full flex items-center justify-center font-bold text-gray-400">
            <p>ไม่พบบันทึก</p>
          </div>
        )}
      </ScrollArea>
    </div>
  );
};
const WithdrawDialog = ({ user, fetchData, getUserInfo }) => {
  const { toast } = useToast();
  const { profileState, callApi, updateProfileState } = Context();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [withdraw, setWithdraw] = useState(null);

  const submitDeposit = async () => {
    setLoading(true);
    const { status, message } = await callApi(
      "affiliates-createWithdrawTransactionByAdmin",
      {
        userId: user.userId,
        withdraw,
        note: noteValue,
      }
    );
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      fetchData();
      setNoteValue("");
      setWithdraw(null);
      setIsOpen(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> {message || "อัพเดตข้อมูลไม่สำเร็จ"}
          </div>
        ),
      });
    }
    getUserInfo();
    setLoading(false);
  };
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger>
        <button
          onClick={() => setIsOpen(true)}
          className=" w-full bg-[#c15b78] font-bold text-gray-50 rounded-lg py-2 px-3 hover:opacity-85"
        >
          ถอน
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent className="min-w-96">
        <AlertDialogHeader>
          <AlertDialogTitle className={`text-2xl`}>ถอนเงิน</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="grid grid-cols-2 gap-3">
          <div className="h-fit w-full ">
            <Text className={"text-sm font-medium"}>เลขที่บัญชี</Text>
            <Text className="font-bold text-xl">{user.userId}</Text>
          </div>
          <div className="h-fit w-full ">
            <Text className={"text-sm font-medium"}>ชื่อ-นามสกุล</Text>
            <Text className="font-bold text-xl">
              {user.firstName + " " + user.lastName}
            </Text>
          </div>
          <div className="h-fit w-full col-span-2 ">
            <Text className={"text-sm font-medium"}>เบอร์โทรศัพท์</Text>
            <Text className="font-bold text-xl">{user.phone}</Text>
          </div>
          <div className="h-fit w-full col-span-2 ">
            <Text className={"text-sm font-medium"}>จำนวนเงินถอน</Text>
            <Input
              className="font-bold text-xl"
              type="number"
              value={withdraw}
              onChange={(e) => setWithdraw(e.target.value)}
              placeholder={0}
            />
          </div>

          <div className="h-fit col-span-2 w-full ">
            <Text className={"text-sm font-medium"}>บันทึกเพิ่มเติม</Text>
            <Input
              className="font-bold text-xl"
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              placeholder={"บันทึกเพิ่มเติม"}
            />
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => setIsOpen(false)}
            className={`bg-white border-0`}
          >
            ยกเลิก
          </AlertDialogCancel>
          <AlertDialogAction
            className={`bg-[rgb(59,176,101)]`}
            onClick={() => withdraw > 0 && submitDeposit()}
            disabled={withdraw < 1}
          >
            {isLoading ? <Loading className={`h-3 w-3`} /> : "ยืนยัน"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
const DepositDialog = ({ user, fetchData, getUserInfo }) => {
  const { toast } = useToast();
  const { profileState, callApi } = Context();
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [deposit, setDeposit] = useState(null);

  const submitDeposit = async () => {
    setLoading(true);
    const { status, message } = await callApi(
      "affiliates-createDepositTransaction",
      {
        userId: user.userId,
        deposit,
        note: noteValue,
      }
    );
    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      fetchData();
      setNoteValue("");
      getUserInfo();
      setDeposit(null);
      setIsOpen(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> {message || "อัพเดตข้อมูลไม่สำเร็จ"}
          </div>
        ),
      });
    }
    setLoading(false);
  };
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogTrigger>
        <button
          onClick={() => setIsOpen(true)}
          className=" w-full bg-[#37beb0] font-bold text-gray-50 rounded-lg py-2 px-3 hover:opacity-85"
        >
          ฝาก
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent className="min-w-96">
        <AlertDialogHeader>
          <AlertDialogTitle className={`text-2xl`}>ฝากเงิน</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="grid grid-cols-2 gap-3">
          <div className="h-fit w-full ">
            <Text className={"text-sm font-medium"}>เลขที่บัญชี</Text>
            <Text className="font-bold text-xl">{user.userId}</Text>
          </div>
          <div className="h-fit w-full ">
            <Text className={"text-sm font-medium"}>ชื่อ-นามสกุล</Text>
            <Text className="font-bold text-xl">
              {user.firstName + " " + user.lastName}
            </Text>
          </div>
          <div className="h-fit w-full col-span-2 ">
            <Text className={"text-sm font-medium"}>เบอร์โทรศัพท์</Text>
            <Text className="font-bold text-xl">{user.phone}</Text>
          </div>
          <div className="h-fit w-full col-span-2 ">
            <Text className={"text-sm font-medium"}>จำนวนเงินฝาก</Text>
            <Input
              className="font-bold text-xl"
              type="number"
              value={deposit}
              onChange={(e) => setDeposit(e.target.value)}
              placeholder={0}
            />
          </div>

          <div className="h-fit col-span-2 w-full ">
            <Text className={"text-sm font-medium"}>บันทึกเพิ่มเติม</Text>
            <Input
              className="font-bold text-xl"
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              placeholder={"บันทึกเพิ่มเติม"}
            />
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              setNoteValue("");
              setDeposit(null);
              setIsOpen(false);
            }}
            className={`bg-white border-0`}
          >
            ยกเลิก
          </AlertDialogCancel>
          <AlertDialogAction
            className={`bg-[rgb(59,176,101)]`}
            onClick={() => deposit > 0 && submitDeposit()}
            disabled={deposit < 1}
          >
            {isLoading ? <Loading className={`h-3 w-3`} /> : "ยืนยัน"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const PointTransaction = ({ user, selectedUser }) => {
  const [isLoading, setLoading] = useState(false);
  const [referralInfo, setReferralInfo] = useState(false);
  const { themes, callApi, configs } = Context();
  useEffect(() => {
    setReferralInfo(false);
  }, [selectedUser]);
  const fetchData = async () => {
    setLoading(true);

    let result = await callApi("referralPoints-getUserReferrer", {
      userId: user.userId,
      admin: true,
    });

    result.history = [...result.orders, ...result.users];

    setReferralInfo([]);
    setReferralInfo(result);
    setLoading(false);
  };

  return (
    <Container className={`relative p-3 h-fit duration-150 w-full`}>
      <div className="flex gap-2 items-center justify-between">
        <div className="flex gap-2 items-center">
          <BsPiggyBank className={themes.text} />
          <Text className="text-md font-bold">ประวัติคะแนน</Text>
        </div>
        {referralInfo.status || (
          <button
            onClick={() => fetchData()}
            className="bg-gray-950 text-white flex gap-2 items-center  rounded-lg hover:opacity-65 duration-150 py-1 px-3"
          >
            {isLoading ? (
              <div className="flex items-center justify-center p-2">
                <Loading className={`w-2 h-2`} />
              </div>
            ) : (
              <p className="font-semibold">ดูประวัติคะแนน</p>
            )}
          </button>
        )}
      </div>
      {referralInfo ? (
        referralInfo.status ? (
          <div className="flex flex-col pt-2 gap-2">
            <div className="flex gap-2 ">
              <div className="flex flex-col justify-between py-2 px-3 h-32 w-1/4 col-span-1 bg-[#095256] text-white rounded-lg duration-300">
                <p className="text-sm font-medium">ลิ้งก์สมาชิกสำเร็จ</p>
                <div className="text-2xl text-center font-bold">
                  {referralInfo.users.length?.toLocaleString()}
                </div>
                <p className="text-sm text-right font-medium">ลิ้งก์</p>
              </div>
              <div className="flex flex-col justify-between py-2 px-3 h-32 w-1/4 bg-[#52B788] text-white rounded-lg  duration-300">
                <p className="text-sm font-medium">ออเดอร์สำเร็จ</p>
                <div className="text-2xl text-center font-bold">
                  {referralInfo.totalOrder?.toLocaleString()}
                </div>
                <p className="text-sm text-right font-medium">ออเดอร์</p>
              </div>
              <div className="flex flex-col justify-between py-2 px-3 h-32 w-1/2 bg-[#FAB12F] text-white rounded-lg  duration-300">
                <p className="text-sm font-medium">
                  รายได้ที่มี{" "}
                  <span className="text-xs font-bold">(ไม่รวมงวดนี้)</span>
                </p>
                <div className="text-2xl text-center font-bold">
                  {(user.buyCash || 0)?.toLocaleString()}
                </div>
                <p className="text-sm text-right font-medium">บาท</p>
              </div>
            </div>
            <div className="flex items-center justify-start shadow-sm border-1 border-gray-200 w-full bg-white rounded-lg p-4">
              <p className="text-2xl font-medium">ประวัติรายได้ </p>
              {/* <span className=" font-medium mx-2 text-yellow-500">| </span> */}

              {/* <p className="font-semibold items-center">
                ใบละ{" "}
                <span className="text-2xl  font-bold text-green-500">2</span>{" "}
                บาท
              </p> */}
            </div>

            <ScrollArea
              className={`${
                referralInfo.history.length > 5 ? "h-[500px]" : "h-fit"
              } px-3 border-1 border-gray-200 rounded-lg`}
            >
              {referralInfo.history.length > 0 ? (
                referralInfo.history
                  .sort((a, b) => b.createdAt - a.createdAt)
                  .map((order, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex justify-between items-end w-full ${
                          referralInfo.history.length - 1 === index
                            ? "border-0"
                            : "border-b-2"
                        } bg-white p-4 gap-4 duration-150 `}
                      >
                        <div className="flex flex-col">
                          <p className="text-sm font-medium">เลขที่อ้างอิง</p>
                          <p className="text-2xl font-bold">
                            {order.shortId || order.userId}
                          </p>
                          <p className="text-sm font-medium text-gray-400">{`${
                            new Date(order.createdAt).toLocaleDateString(
                              "th-TH",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            ) +
                            " | " +
                            new Date(order.createdAt).toLocaleString("th-TH", {
                              hour: "2-digit",
                              minute: "2-digit",
                            }) +
                            " น."
                          }`}</p>
                        </div>
                        {order.shortId ? (
                          <div className="flex flex-col ">
                            <p className="text-2xl font-bold">
                              {order.size} ใบ
                            </p>
                            <p className="text-xs font-semibold text-green-500 mr-2">
                              +{order.size * 2} บาท
                            </p>
                          </div>
                        ) : (
                          <div className="self-center">
                            <p className="text-md text-green-500 font-medium mr-2">
                              + สมัครสมาชิกสำเร็จ
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })
              ) : (
                <div className="flex items-center justify-center w-full h-60">
                  <p className="text-gray-400 font-semibold">ไม่พบออเดอร์</p>
                </div>
              )}
            </ScrollArea>
            {/* <div className="flex bg-green-500 rounded-lg justify-between items-end w-full border-b-2  p-4 gap-4 duration-150 ">
              <p className="text-2xl text-white font-bold">รายได้งวดนี้</p>

              <p className="text-2xl text-white font-bold">12 บาท</p>
            </div> */}
          </div>
        ) : (
          <div className="flex justify-center items-center w-full h-80">
            ไม่พบข้อมูล
          </div>
        )
      ) : (
        <></>
      )}
    </Container>
  );
};

const Notes = ({ notes, updateNotes }) => {
  const [currentNote, setCurrentNote] = useState();
  const [isLoading, setLoading] = useState(false);
  const { isDarkTheme, themes } = Context();

  const handleUpdateNote = () => {
    setLoading(true);
    const status = updateNotes(currentNote);
    if (status) {
      setCurrentNote("");
    }
    setLoading(false);
  };

  return (
    <Container
      className={`  ${
        isDarkTheme ? "bg-[rgb(255,255,255)]" : "bg-[rgb(26,29,32)]"
      } w-full p-3  duration-300`}
    >
      <Text
        className={` ${
          isDarkTheme ? "text-grey-900" : "text-[rgb(255,255,255)]"
        } text-xl mb-3 font-semibold`}
      >
        บันทึกเพิ่มเติม
      </Text>
      <div className={`relative mb-3 w-full flex-grow`}>
        <div className="flex">
          <Input
            className={`${themes.bg.foreground} focus:outline-[rgb(59,176,101)]`}
            placeholder="บันทึก"
            value={currentNote}
            onChange={(e) => setCurrentNote(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleUpdateNote()}
          />
          {isLoading ? (
            <div className="flex justify-center items-center px-4 m-1 bg-[rgb(59,176,101)] text-xl text-gray-50 rounded-lg duration-200">
              <Loading className="w-3 h-3" />
            </div>
          ) : (
            <button
              className="px-3 my-1 ml-1 mr-0 bg-[rgb(59,176,101)] text-gray-50 rounded-lg hover:opacity-85 duration-200"
              onClick={() => handleUpdateNote()}
            >
              <RiStickyNoteAddFill className="w-6 h-6" />
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-36 w-full">
          <Loading className={"text-white"} />
        </div>
      ) : notes?.length > 0 ? (
        <div className="flex flex-col gap-2">
          <ScrollArea
            className={`relative rounded-lg ${
              notes?.length > 4 ? "h-96" : "h-fit"
            }`}
          >
            {notes?.map((note) => {
              return (
                <div key={note.time} className="relative flex justify-end p-3">
                  <div className="flex flex-col">
                    <p className="text-xs text-white font-semibold">
                      {note.by}{" "}
                    </p>
                    <p className="text-lg min-w-48 max-w-80 my-1 px-3 py-2 break-words rounded-lg text-gray-50 font-medium bg-[#168AFF]">
                      {note.note}
                    </p>
                    <p className="text-xs text-white self-end">
                      {new Date(note.time).toLocaleDateString("th-TH", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}{" "}
                      น.
                    </p>
                  </div>
                </div>
              );
            })}
          </ScrollArea>
        </div>
      ) : (
        <Text className="text-gray-500 py-24 text-sm text-center">
          ไม่พบบันทึก
        </Text>
      )}
    </Container>
  );
};

const UserDetail = ({
  selectedUser,
  setSelectedUser,
  user,

  getUserInfo,
}) => {
  const { callApi } = Context();
  const { toast } = useToast();

  const { themes } = Context();

  const updateProfile = async (key, value) => {
    if (!key) return false;
    const confirm = window.confirm("ยืนยันการแก้ไข");
    if (!confirm) return false;

    toast({
      className: "bg-[#FA812F]",
      title: (
        <div className="flex gap-2 items-center">
          <Loading /> กำลังอัพเดตข้อมูล
        </div>
      ),
    });

    const result = await callApi("users-adminUpdateUser", {
      userId: user.userId,
      key: key,
      value: value,
    });

    if (result) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      getUserInfo();

      return true;
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });

      return false;
    }
  };

  if (!selectedUser || !user) return;

  console.log("user", user.dob);
  console.log("user", new Date(user.dob));
  return (
    <Container className={`relative p-3 h-fit duration-150 w-full`}>
      <div className={`flex justify-between  flex-row gap-3`}>
        <div className={`flex gap-3  items-center flex-row`}>
          {user?.isLoading ? (
            <Skeleton className={`${"w-24 h-24 duration-250"} rounded-full`} />
          ) : (
            <Avatar className={"w-24 h-24 duration-150"}>
              <AvatarImage
                src={user?.profileImage || "https://github.com/shadcn.png"}
              />
              <AvatarFallback>{user?.firstName?.slice(0, 2)}</AvatarFallback>
            </Avatar>
          )}
          {user.isLoading ? (
            <div>
              <Skeleton className="h-4 m-1 w-[250px]" />
              <Skeleton className="h-4 m-1 w-[250px]" />
            </div>
          ) : (
            <div className="flex flex-col justify-center gap-1 items-center md:items-start">
              <div className="flex items-center gap-1 ">
                <Text className={`text-xl sm:text-xl font-bold center`}>
                  {`${user?.firstName} ${user?.lastName}`}{" "}
                </Text>
                <Text className={`text-sm text-gray-400 font-bold`}>
                  #{user?.userId}
                </Text>
              </div>
              <Text className={`text-sm text-gray-700 font-bold`}>
                เป็นสมาชิกตั้งแต่วันที่{" "}
                {new Date(user?.createdAt).toLocaleDateString("th-TH", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}{" "}
                น.
              </Text>
              <Text className={`text-sm text-blue-500 font-bold`}>
                คะแนนสะสม {user?.buyPoint?.toLocaleString() || 0} คะแนน
              </Text>
              <Text className={`text-sm mb-3 text-green-500 font-bold`}>
                รายได้สะสม {user?.buyCash?.toLocaleString() || 0} บาท
              </Text>

              <div className="flex gap-2">
                <button
                  className={`bg-[rgb(1,113,216)] w-fit rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                  onClick={async () => {
                    let reasult = await callApi("users-addAdminToken", {
                      userId: user.userId,
                    });

                    window.open(
                      // constant.webConfigs.customerUrl +
                      "https://shop.hongthongofficial.com/" +
                        "orders?adminToken=" +
                        reasult.adminToken
                    );
                  }}
                >
                  เข้าสู่ระบบด้วยบัญชีนี้
                </button>
                <button
                  className={`flex gap-2 items-center ${
                    user.disabled?.cart
                      ? "bg-[#c15b78]"
                      : "bg-[rgb(59,176,101)]"
                  } w-fit rounded-md px-3 py-2 text-xs text-white font-semibold hover:font-bold duration-200 hover:shadow-lg`}
                  onClick={() => {
                    updateProfile({ disabled: { cart: !user.disabled?.cart } });
                  }}
                >
                  <FaShoppingCart /> สถานะตระกร้า:{" "}
                  {user.disabled?.cart ? "ปิด" : "เปิด"}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className={`flex gap-3 justify-end`}>
          <>
            <FiShare2
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://admin.hongthongofficial.com/users?${user?.userId}`
                );

                toast({
                  title: "คัดลอกสำเร็จ",
                  description: `https://admin.hongthongofficial.com/users?${user?.userId}`,
                });
              }}
              className="cursor-pointer text-gray-400 duration-150 hover:text-gray-900"
            />
          </>

          <FaTimes
            onClick={() => setSelectedUser(false)}
            className="cursor-pointer text-gray-400 duration-150 hover:text-red-600"
          />
        </div>
      </div>

      <div className="mt-3">
        <Separator />

        <div>
          <div className="flex mt-3 gap-2 items-center">
            <CgProfile className={themes.text} />
            <Text className="text-md font-bold">ข้อมูลส่วนตัว</Text>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 max-w-[800px] gap-3 mt-3 w-full">
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>ชื่อ</Text>
              <Input
                className="font-semibold"
                placeholder="ชื่อ"
                defaultValue={user.firstName || ""}
                onBlur={async (e) => {
                  if (
                    user.firstName !== e.target.value ||
                    e.target.value === ""
                  ) {
                    const result = await updateProfile(
                      "firstName",
                      e.target.value
                    );
                    console.log("🚀 ~ e.target.value :", e.target.value === "");

                    if (result) return e;
                    e.target.value = user.firstName;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>นามสกุล</Text>
              <Input
                className="font-semibold"
                placeholder="นามสกุล"
                defaultValue={user.lastName || ""}
                onBlur={async (e) => {
                  if (user.lastName !== e.target.value) {
                    const result = await updateProfile(
                      "lastName",
                      e.target.value
                    );

                    if (result) return e;

                    e.target.value = user.lastName;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>เบอร์โทรศัพท์</Text>
              <Input
                className="font-semibold"
                placeholder="เบอร์โทรศัพท์"
                defaultValue={user.phone || ""}
                onBlur={async (e) => {
                  if (user.phone !== e.target.value) {
                    const result = await updateProfile("phone", e.target.value);

                    if (result) return e;

                    e.target.value = user.phone;
                  }
                }}
              />
            </div>

            <div className="h-fit w-full cursor-pointer">
              <Text className={"text-sm font-medium"}>สัญชาติ</Text>
              <Select
                className="cursor-pointer"
                value={user.nationality}
                onValueChange={(e) => {
                  updateProfile("nationality", e);
                }}
              >
                <SelectTrigger
                  className={`my-1 text-md font-semibold disabled:opacity-100`}
                >
                  <SelectValue placeholder={`-`} />
                </SelectTrigger>
                <SelectContent className="bg-black">
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="TH"
                  >
                    ไทย
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="LAO"
                  >
                    ลาว
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="h-fit w-full md:col-span-2">
              <Text className={"text-md  mb-2"}>ที่อยู่</Text>
              <Textarea
                className="font-semibold text-md"
                placeholder="ที่อยู่"
                defaultValue={user.address?.addr || ""}
                onBlur={async (e) => {
                  if (user.address?.addr !== e.target.value) {
                    const result = await updateProfile("address", {
                      ...user.address,
                      addr: e.target.value,
                    });

                    if (result) return e;

                    e.target.value = user.address?.addr;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full">
              <Text className={"text-sm font-medium"}>วันเกิด</Text>

              {/* {user?.dob ? (
                <input
                  type="text"
                  className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full "
                  value={new Date(user.dob).toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                  disabled
                />
              ) : ( */}
              <DatePicker
                className="w-full h-12 border-2 border-gray-300 font-semibold rounded-lg p-2 "
                locale={buddhistLocale}
                // value={new Date(user.dob).toLocaleDateString("th-TH", {
                //   year: "numeric",
                //   month: "long",
                //   day: "numeric",
                // })}
                value={dayjs(user.dob, "YYYY-MM-DD")}
                onChange={async (en, th) => {
                  const selectedDate =
                    th.split("-")[2] -
                    543 +
                    "-" +
                    th.split("-")[1] +
                    "-" +
                    th.split("-")[0];

                  await updateProfile("dob", selectedDate);
                }}
              />
              {/* )} */}
            </div>
            <div className="h-fit w-full">
              <Text className={"text-sm font-medium"}>จังหวัด</Text>
              <Input
                className="font-semibold"
                placeholder="จังหวัด"
                defaultValue={user.address?.province || ""}
                onBlur={async (e) => {
                  if (user.address?.province !== e.target.value) {
                    const result = await updateProfile("address", {
                      ...user.address,
                      province: e.target.value,
                    });

                    if (result) return e;

                    e.target.value = user.address?.province;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>รหัสไปรษณีย์</Text>
              <Input
                className=" font-semibold"
                placeholder="รหัสไปรษณีย์"
                defaultValue={user.address?.zipcode || ""}
                onBlur={async (e) => {
                  if (user.address?.zipcode !== e.target.value) {
                    const result = await updateProfile("address", {
                      ...user.address,
                      zipcode: e.target.value,
                    });

                    if (result) return e;

                    e.target.value = user.address?.zipcode;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>แจ้งเตือนผ่านทาง</Text>
              <Select
                value={user?.notifyType}
                onValueChange={async (e) => {
                  if (user.notifyType !== e) {
                    const result = await updateProfile("bank", {
                      ...user.bank,
                      name: e,
                    });

                    if (result) return e;
                    e = user.notifyType;
                  }
                }}
              >
                <SelectTrigger className="my-1 text-md font-semibold disabled:opacity-85">
                  <SelectValue placeholder="-"></SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-black">
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="LINE"
                    key="LINE"
                  >
                    LINE
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="SMS"
                    key="SMS"
                  >
                    SMS
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <Separator className="mb-3 mt-6" />
          <div className="flex mt-3 gap-2 items-center">
            <MdAccountBalance className={themes.text} />
            <Text className="text-md font-bold">บัญชีธนาคาร</Text>
          </div>
          <div className="grid grid-cols-2 max-w-[800px] items-end gap-3 mt-3 w-full">
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>ธนาคาร</Text>
              <Select
                value={user?.bank?.name}
                onValueChange={async (e) => {
                  if (user.bank?.name !== e) {
                    const result = await updateProfile("bank", {
                      ...user.bank,
                      name: e,
                    });

                    if (result) return e;
                    e = user.bank?.name;
                  }
                }}
              >
                <SelectTrigger className="my-1 text-md font-semibold disabled:opacity-85">
                  <SelectValue placeholder="-"></SelectValue>
                </SelectTrigger>
                <SelectContent className="bg-black">
                  {banks.map((bank) => {
                    return (
                      <SelectItem
                        className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                        value={bank}
                        key={bank}
                      >
                        {bank}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>ชื่อบัญชี</Text>
              <Input
                className="font-semibold"
                placeholder="ชื่อบัญชี"
                defaultValue={user.bank?.accName || ""}
                onBlur={async (e) => {
                  if (user.bank?.accName !== e.target.value) {
                    const result = await updateProfile("bank", {
                      ...user.bank,
                      accName: e.target.value,
                    });

                    if (result) return e.target.value;
                    e = user.bank?.accName;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>เลขบัญชี</Text>
              <Input
                className="font-semibold"
                placeholder="เลขบัญชี"
                defaultValue={user.bank?.accNo || ""}
                onBlur={async (e) => {
                  if (user.bank?.accNo !== e.target.value) {
                    const result = await updateProfile("bank", {
                      ...user.bank,
                      accNo: e.target.value,
                    });

                    if (result) return e.target.value;
                    e = user.bank?.accNo;
                  }
                }}
              />
            </div>
            <div className="h-fit w-full ">
              <Text className={"text-sm font-medium"}>ค่าเงิน</Text>
              <Select
                value={user.bank?.currency}
                onValueChange={async (e) => {
                  if (user.bank?.currency !== e) {
                    const result = await updateProfile("bank", {
                      ...user.bank,
                      currency: e,
                    });

                    if (result) return e;
                    e = user.bank?.currency;
                  }
                }}
              >
                <SelectTrigger className="my-1 text-md font-semibold disabled:opacity-85">
                  <SelectValue placeholder="--ยังไม่ได้เลือก--" />
                </SelectTrigger>
                <SelectContent className="bg-black">
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="THB"
                  >
                    บาท (ไทย)
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="LAK"
                  >
                    กีบ (ลาว)
                  </SelectItem>
                  <SelectItem
                    className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                    value="KRW"
                  >
                    วอน (เกาหลี)
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const UserList = ({
  userData,
  isDarkTheme,
  setSelectedUser,
  isSelectedUser,
  isNotLast,
}) => {
  const handleSelectUser = (id) => {
    if (isSelectedUser) {
      setSelectedUser(false);
    } else {
      localStorage.setItem("selectedUser", userData.id);
      setSelectedUser(userData.id);
    }
  };
  return (
    <div>
      <div
        className={`flex gap-3 items-center p-3 rounded-lg duration-100 cursor-pointer ${
          isSelectedUser
            ? isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white "
              : "bg-[rgb(26,29,32)] text-gray-50 "
            : isDarkTheme
            ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
            : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
        }`}
        onClick={() => {
          handleSelectUser();
        }}
      >
        <Avatar className={"w-12 h-12"}>
          <AvatarImage
            src={userData.profileImage || "https://github.com/shadcn.png"}
          />
          <AvatarFallback>{userData.firstName.slice(0, 2)} </AvatarFallback>
        </Avatar>
        <div>
          <p className={`text-sm font-semibold max-w-48 truncate`}>
            {`${userData.firstName} ${userData.lastName}`}
          </p>
          <p
            className={`text-sm font-semibold text-gray-400 max-w-48 truncate
      `}
          >
            {userData.phone}
          </p>
          <p
            className={`text-sm font-semibold text-gray-400 max-w-48 truncate
      `}
          >
            {userData.id}
          </p>
        </div>
      </div>
      {isNotLast && <Separator className="my-2" />}
    </div>
  );
};
