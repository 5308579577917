import { useState } from "react";
import { add } from "date-fns";
import {
  Loading,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/modules";

import {
  Container,
  Text,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Button,
  Switch,
  TimePickerDemo,
} from "../../components/base";
import {
  ScrollArea,
  Separator,
  useToast,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui";

import Context from "../../Context";

import { FaRegCalendarPlus } from "react-icons/fa6";
import { IoMdCalendar } from "react-icons/io";
import { FaTimes, FaCheckCircle } from "react-icons/fa";

import { unixToDate } from "../../util";

export const Setting = () => {
  const { toast } = useToast();
  const { callApi, updateConfig, configs } = Context();

  const [selectedSetting, setSelectedSetting] = useState(
    localStorage.getItem("selectedSetting") || "ทั่วไป"
  );

  const [isOpenConfirmSheet, setIsOpenConfirmSheet] = useState(false);

  const updateChange = async (key, value) => {
    await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });
    updateConfig();
  };
  return (
    <>
      <Dialog
        isOpenConfirmSheet={isOpenConfirmSheet}
        setIsOpenConfirmSheet={setIsOpenConfirmSheet}
      />
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 mb-3">
        <div
          className={`py-2 px-3 w-full ${
            configs.isOpen?.store ? "bg-[rgb(59,176,101)]" : "bg-[#c15b78]"
          } text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300`}
          onClick={() =>
            setIsOpenConfirmSheet({
              body: {
                text: configs?.isOpen?.store
                  ? `ยืนยันการปิดร้านค้า?`
                  : `ยืนยันการเปิดร้านค้า?`,
                func: () =>
                  updateChange("isOpen.store", !configs.isOpen?.store),
              },
            })
          }
        >
          <p className="text-sm font-medium">สภานะร้านค้า</p>
          <div className="text-2xl text-center pt-2 mt-3 mb-4 font-bold">
            {configs ? configs.isOpen?.store ? "เปิด" : "ปิด" : <Loading />}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[#ffb067] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => setSelectedSetting("ไปรษณีย์")}
        >
          <p className="text-sm font-medium">รอบจัดส่งวันที่ </p>
          <div className="text-2xl text-center pt-2 mt-3 mb-4 font-bold">
            {!configs ? (
              <Loading />
            ) : (
              configs.shipping.sendDate || unixToDate(configs.shipping.send)
            )}
          </div>
        </div>
        <div
          className="py-2 px-3 w-full bg-[#37beb0] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300"
          onClick={() => setSelectedSetting("ราคา")}
        >
          <p className="text-sm font-medium">ราคาสลาก</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {!configs ? (
              <Loading />
            ) : (
              <div className="flex justify-center items-center w-full">
                <div className="w-20">
                  <p className="text-xs">ราคา</p>
                  <p>{configs?.price}</p>
                </div>

                <Separator
                  className={`h-12 w-[2px] bg-white`}
                  orientation={false}
                />
                <div className="w-20">
                  <p className="text-xs">ค่าฝาก</p>
                  <p>{configs?.fee}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="py-2 px-3 w-full bg-[#c15b78] text-white rounded-2xl hover:cursor-pointer hover:scale-[98%] duration-300">
          <p className="text-sm font-medium">ยกเลิก</p>
          <div className="text-2xl text-center mt-3 mb-4 font-bold">
            {/* {configs ? <Loading /> : orderSummary?.canceled?.toLocaleString()} */}
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full lg:flex-row gap-3 flex-grow duration-300">
        {/* order list */}
        <Container
          className={` p-1 w-full lg:w-60 h-fit shadow-md duration-300`}
        >
          <ScrollArea className={`relative h-fit px-2 py-0 my-2`}>
            {["ทั่วไป", "ราคา", "ไปรษณีย์", "การแสดงปุ่ม"].map(
              (name, index) => {
                return (
                  <SettingList
                    name={name}
                    selectedSetting={selectedSetting === name}
                    isNotLast={index !== 3}
                    setSelectedSetting={setSelectedSetting}
                    key={name}
                  />
                );
              }
            )}
          </ScrollArea>
        </Container>

        {selectedSetting === "ทั่วไป" && (
          <GeneralSetting setIsOpenConfirmSheet={setIsOpenConfirmSheet} />
        )}

        {selectedSetting === "การแสดงปุ่ม" && (
          <ButtonSetting setIsOpenConfirmSheet={setIsOpenConfirmSheet} />
        )}
      </div>
    </>
  );
};

const GeneralSetting = ({ setIsOpenConfirmSheet }) => {
  const { configs, updateConfig, callApi } = Context();
  configs.roundDates = configs.roundDates.reverse();

  const [date, setDate] = useState(configs.nextRoundDateTime);

  const handleSelect = (newDay) => {
    console.log("newDay", newDay);
    if (!newDay) return;
    // if (!date) {
    setDate(newDay);
    return;
    // }
    // const diff = newDay.getTime() - date.getTime();
    // const diffInDays = diff / (1000 * 60 * 60 * 24);
    // const newDateFull = add(date, { days: Math.ceil(diffInDays) });
    // setDate(newDateFull);
  };

  const updateChange = async (key, value) => {
    const result = await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });

    if (result) {
      updateConfig();
      return result;
    }
  };

  const addNewRoundDate = async (newRoundDate) => {
    await callApi("configs-addNewRoundDate", {
      roundDate: newRoundDate,
    });
    updateConfig();
  };

  return (
    <div className="flex-grow ">
      {/* general */}
      <Container className={`relative h-fit p-3 duration-300 mb-3`}>
        <Text className={`text-2xl font-semibold mb-8`}>ตั้งค่าทั่วไป</Text>
        {/* isOpen.store */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>
            เปิดขาย<span className="font-semibold">สลาก</span>
          </Text>

          <Switch
            checked={configs?.isOpen?.store}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: configs?.isOpen?.store
                    ? `ยืนยันการปิดขายคูปอง?`
                    : `ยืนยันการเปิดขายคูปอง?`,
                  func: async () => await updateChange("isOpen.store", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* isOpen.coupon */}

        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>
            เปิดขาย<span className="font-semibold">คูปอง</span>
          </Text>

          <Switch
            checked={configs?.isOpen?.coupon}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: configs?.isOpen?.coupon
                    ? `ยืนยันการปิดร้านค้า?`
                    : `ยืนยันการเปิดร้านค้า?`,
                  func: () => updateChange("isOpen.coupon", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* isOpen.product */}

        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>
            เปิดขาย<span className="font-semibold">ร้านค้า</span>
          </Text>

          <Switch
            checked={configs?.isOpen?.productStore}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: configs?.isOpen?.productStore
                    ? `ยืนยันการปิดร้านค้า?`
                    : `ยืนยันการเปิดร้านค้า?`,
                  func: () => updateChange("isOpen.productStore", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* isOpen.mission */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>
            เปิดทำ<span className="font-semibold">ภารกิจ</span>
          </Text>
          <Switch
            checked={configs?.isOpen?.mission}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: configs?.isOpen?.mission
                    ? `ยืนยันการปิดร้านค้า?`
                    : `ยืนยันการเปิดร้านค้า?`,
                  func: () => updateChange("isOpen.mission", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* isOpen.donate */}
        <div className="flex gap-3 mb-2 justify-between">
          <Text className={`font-medium`}>
            เปิดรับ<span className="font-semibold">บริจาค</span>
          </Text>
          <Switch
            checked={configs?.isOpen?.donate}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: configs?.isOpen?.donate
                    ? `ยืนยันการปิดรับบริจาค?`
                    : `ยืนยันการเปิดรับบริจาค?`,
                  func: () => updateChange("isOpen.donate", e),
                },
              })
            }
          />
        </div>
      </Container>
      {/* store setting */}
      <Container className={`relative h-fit p-3 duration-300 mb-3`}>
        <Text className={`text-2xl font-semibold mb-8`}>ตั้งค่าร้านค้า</Text>
        {/* roundDate */}
        <div className="flex gap-3 items-center justify-between">
          <Text className={`font-medium`}>
            งวด<span className="font-semibold">ปัจจุบัน</span>
          </Text>
          <div>
            <Select
              defaultValue={configs?.roundDate}
              onValueChange={(e) => {
                setIsOpenConfirmSheet({
                  body: {
                    text: `ยืนยันการเปลี่ยนงวด ${e} ?`,
                    func: () => updateChange("roundDate", e),
                  },
                });
              }}
            >
              <SelectTrigger
                variant={"outline"}
                className={`w-52 py-2 flex justify-center rounded-md items-center border-2 relative font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50`}
              >
                <SelectValue placeholder="-"></SelectValue>
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                      key={roundDate}
                    >
                      <div className="flex gap-2 items-center">
                        <IoMdCalendar className="text-[20px]" />
                        <p className="font-semibold text-sm">{roundDate}</p>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className="mt-4 mb-4" />
        {/* open time */}
        <div className="flex gap-3 items-center justify-between">
          <Text className={`font-medium`}>วัน-เวลาที่เปิดขาย</Text>
          <div className="">
            <Popover>
              <PopoverTrigger
                variant={"outline"}
                className={`w-64 py-2 flex justify-center rounded-md items-center border-2 relative font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50`}
              >
                <IoMdCalendar className="text-[20px] mr-2" />
                {date
                  ? new Date(date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })
                  : "Pick a date"}
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={new Date(date)}
                  onSelect={(d) => handleSelect(d)}
                  initialFocus
                />
                <div className="p-3 border-t border-border">
                  <TimePickerDemo setDate={setDate} date={new Date(date)} />
                </div>
                <Button
                  className={`ml-3 mb-3`}
                  onClick={() => {
                    setIsOpenConfirmSheet({
                      body: {
                        text: `ต้องการเพิ่มงวดวันที่ ${new Date(
                          date
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })} ?`,
                        func: () =>
                          updateChange(
                            "nextRoundDateTime",
                            new Date(date).getTime()
                          ),
                      },
                    });
                  }}
                >
                  ยืนยัน
                </Button>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <Separator className="mt-4 mb-4" />
        {/* next roundDate */}
        <div className="flex gap-3 items-center justify-between">
          <Text className={`font-medium`}>
            งวด<span className="font-semibold">ถัดไป</span>
          </Text>
          <div>
            <Select
              defaultValue={configs?.nextRoundDate}
              onValueChange={(e) => {
                setIsOpenConfirmSheet({
                  body: {
                    text: `ยืนยันการเปลี่ยนงวดของงวดถัดไป ${e} ?`,
                    func: () => updateChange("nextRoundDate", e),
                  },
                });
              }}
            >
              <SelectTrigger
                variant={"outline"}
                className={`w-52 py-2 flex justify-center rounded-md items-center border-2 relative font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50`}
              >
                <SelectValue placeholder="-"></SelectValue>
              </SelectTrigger>
              <SelectContent className="bg-black">
                {configs.roundDates.map((roundDate) => {
                  return (
                    <SelectItem
                      className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                      value={roundDate}
                      key={roundDate}
                    >
                      <div className="flex gap-2 items-center">
                        <IoMdCalendar className="text-[20px]" />
                        <p className="font-semibold text-sm">{roundDate}</p>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className="mt-4 mb-4" />
        {/* add roundDate */}
        <div className="flex gap-3 items-center justify-between">
          <Text className={`font-medium`}>เพิ่มงวด</Text>
          <div className="h-full relative">
            <Popover>
              <PopoverTrigger
                variant={"outline"}
                className={`w-40 py-2 flex justify-center rounded-md items-center border-2 relative font-semibold bg-gray-50 hover:bg-gray-950 hover:text-gray-50`}
              >
                เพิ่มงวด
                <FaRegCalendarPlus className="h-full ml-2" />
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0">
                <Calendar
                  mode="single"
                  selected={configs?.roundDate}
                  onSelect={(e) => {
                    let [mm, dd, yyyy] = e.toLocaleDateString().split("/");

                    const dateFormat = [
                      yyyy,
                      mm.length >= 2 ? mm : `0${mm}`,
                      dd.length >= 2 ? dd : `0${dd}`,
                    ].join("-");

                    setIsOpenConfirmSheet({
                      body: {
                        text: `ต้องการเพิ่มงวดวันที่ ${dateFormat} ?`,
                        func: () => addNewRoundDate(dateFormat),
                      },
                    });
                  }}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <Separator className="mt-4 mb-4" />
        {/* isOpen.store */}
        <div className="flex gap-3 justify-between mb-2">
          <Text className={`font-medium`}>ประกาศรางวัลงวดปัจจุบัน</Text>

          <Switch
            checked={configs?.isAwardReady}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: `ยืนยันเปลี่ยนแปลงการประกาศรางวัล?`,
                  func: () => updateChange("isAwardReady", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        <div className="flex gap-3 items-center justify-between">
          <Text className={`font-medium`}>ธนาคาร QR Code</Text>
          <div className="w-44">
            <Select
              value={configs?.payment?.qrChannel}
              onValueChange={(e) => {
                setIsOpenConfirmSheet({
                  body: {
                    text: `ยืนยันการเปลี่ยนธนาคารเป็น ${e} ?`,
                    func: () => updateChange("payment.qrChannel", e),
                  },
                });

                updateConfig();
              }}
            >
              <SelectTrigger className="h-fit py-2 text-sm font-medium">
                <SelectValue placeholder="-"></SelectValue>
              </SelectTrigger>
              <SelectContent className="bg-black">
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={"bbl"}
                >
                  <p className="font-semibold text-sm">BBL</p>
                </SelectItem>
                <SelectItem
                  className="text-lg font-medium text-white bg-gray-950 hover:bg-gray-700"
                  value={"cimb"}
                >
                  <p className="font-semibold text-sm">CIMB</p>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <Separator className="mt-4 mb-4" />
      </Container>
    </div>
  );
};

const ButtonSetting = ({ setIsOpenConfirmSheet }) => {
  const { configs, updateConfig, callApi } = Context();

  const updateChange = async (key, value) => {
    const result = await callApi("configs-edit", {
      id: "general",
      key: key,
      value: value,
    });

    updateConfig();

    return result.status;
  };

  return (
    <div className="flex-grow ">
      {/* general */}
      <Container className={`relative h-fit p-3 duration-300 mb-3`}>
        <Text className={`text-2xl font-semibold mb-8`}>
          ตั้งค่าการแสดงปุ่ม
        </Text>
        {/* showButton.one */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขใบเดียว</Text>
          <Switch
            checked={configs?.showButton.one}
            onCheckedChange={async (e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.one", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.two */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขชุด 2 ใบ</Text>
          <Switch
            checked={configs?.showButton?.two}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.two", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.three */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขชุด 3 ใบ</Text>
          <Switch
            checked={configs?.showButton.three}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.three", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.four */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขชุด 4 ใบ</Text>
          <Switch
            checked={configs?.showButton.four}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.four", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.five */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขชุด 5 ใบ</Text>
          <Switch
            checked={configs?.showButton.five}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.five", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.bigNumber */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขชุดใหญ่</Text>
          <Switch
            checked={configs?.showButton.bigNumber}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.bigNumber", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.random */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขสุ่ม</Text>
          <Switch
            checked={configs?.showButton.random}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.random", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.random */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขสุ่ม</Text>
          <Switch
            checked={configs?.showButton.random}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.random", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.prettyFronts */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขหน้าสวย</Text>
          <Switch
            checked={configs?.showButton.prettyFronts}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.prettyFronts", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.specialNumber */}
        <div className="flex gap-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขเซียนดัง</Text>
          <Switch
            checked={configs?.showButton.specialNumber}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.specialNumber", e),
                },
              })
            }
          />
        </div>
        <Separator className="mt-4 mb-4" />
        {/* showButton.magicNumber */}
        <div className="flex gap-3 mb-3 justify-between">
          <Text className={`font-medium`}>ปุ่มเลขปวงสรวง</Text>
          <Switch
            checked={configs?.showButton.magicNumber}
            onCheckedChange={(e) =>
              setIsOpenConfirmSheet({
                body: {
                  text: "ยืนยันการแก้ไข",
                  func: () => updateChange("showButton.magicNumber", e),
                },
              })
            }
          />
        </div>
      </Container>
    </div>
  );
};

const SettingList = ({
  name,
  isNotLast,
  selectedSetting,
  setSelectedSetting,
}) => {
  const { isDarkTheme } = Context();
  return (
    <>
      <div
        className={`gap-3 items-center p-3 rounded-xl duration-100 cursor-pointer ${
          selectedSetting
            ? isDarkTheme
              ? "text-[rgb(26,29,32)] bg-white"
              : "bg-[rgb(26,29,32)] text-gray-50"
            : isDarkTheme
            ? "text-gray-50 hover:bg-white hover:text-[rgb(26,29,32)]"
            : "hover:bg-[rgb(26,29,32)] hover:text-gray-50"
        }`}
        onClick={() =>
          selectedSetting ? setSelectedSetting(false) : setSelectedSetting(name)
        }
      >
        <p className="font-semibold">{name}</p>
      </div>
      {isNotLast && <Separator className="my-2" />}
    </>
  );
};

const Dialog = ({ isOpenConfirmSheet, setIsOpenConfirmSheet }) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const result = await isOpenConfirmSheet?.body?.func();

    if (result) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: (
          <div className="flex gap-2 items-center">
            <FaCheckCircle /> อัพเดตข้อมูลสำเร็จ
          </div>
        ),
      });
      setIsLoading(false);
      setIsOpenConfirmSheet(false);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> อัพเดตข้อมูลไม่สำเร็จ
          </div>
        ),
      });
      setIsLoading(false);
    }
  };
  return (
    <AlertDialog
      open={isOpenConfirmSheet}
      // onOpenChange={() => setIsOpenConfirmSheet(false)}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{isOpenConfirmSheet?.body?.text}</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel
            className={`bg-white border-0`}
            onClick={() => setIsOpenConfirmSheet(false)}
          >
            ยกเลิก
          </AlertDialogCancel>
          <AlertDialogAction
            className={`bg-[rgb(59,176,101)]`}
            onClick={async () => await handleSubmit()}
          >
            {isLoading ? <Loading className={`h-3 w-3`} /> : "ยืนยัน"}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
